import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { Menu, PageHeader, Spin } from "antd"
import {
  MasterReport,
  ProgressReport,
  Degree360Report,
  AppraisalReport,
} from "@components/report"
import { getPermission } from "@services/auth"
import { navigate } from "gatsby"

const DisplayTable = ({ selectedKey }) => {
  switch (selectedKey) {
    case "master":
      return <MasterReport />
    case "progress":
      return <ProgressReport />
    case "appraisal":
      return <AppraisalReport />
    case "degree360":
      return <Degree360Report />
    default:
      return <>hello</>
  }
}

const ReportPage = () => {
  const [selectedKey, setSelectedKey] = useState("master")
  const [showTitle, setShowTitle] = useState("Master Report")
  const [isLoad, setIsLoad] = useState(false)
  const handleSelect = ({ key }) => {
    if (key === "master") {
      setShowTitle("Master Report")
    } else if (key === "appraisal") {
      setShowTitle("Appraisal Report ")
    } else if (key === "progress") {
      setShowTitle("Progress Report")
    } else if (key === "degree360") {
      setShowTitle("360 Degree Evalutaion")
    }
    setSelectedKey(key)
  }

  const editPermission = async () => {
    try {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      let canAccess = response.includes("Report")
      if (canAccess) {
        setIsLoad(true)
      } else {
        navigate("/")
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    editPermission()
  }, [])

  return isLoad ? (
    <MainLayout>
      <Seo title="Reports" />
      <Menu
        mode="horizontal"
        multiple={false}
        onSelect={handleSelect}
        selectedKeys={selectedKey}
      >
        <Menu.Item key="master">Master Report</Menu.Item>

        <Menu.Item key="appraisal">Appraisal Report</Menu.Item>

        <Menu.Item key="progress">Progress Report</Menu.Item>

        <Menu.Item key="degree360">360 Degree Evalutaion</Menu.Item>
      </Menu>
      <PageHeader title={showTitle} />

      <DisplayTable selectedKey={selectedKey} />
    </MainLayout>
  ) : (
    <Spin size="large" />
  )
}

export default ReportPage
