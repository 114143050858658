import React, { useEffect, useState } from "react"
import { Button, Space, Form, Row, Col, Select, Input, message } from "antd"
import "@style/CycleTableTemplate.less"
import {
  OtherClient,
  AppraisalClient,
  UsersClient,
  AppraisalCycleClient,
} from "@api"

const SearchForm = ({ options, mode }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isDownloading, setIsDownloading] = useState(false)

  const onFinish = async values => {
    console.log(values)
    setIsDownloading(true)
    let client = new AppraisalClient()
    let result
    try {
      if (mode === "master") {
        result = await client.masterReport(
          values.yearOfAppraisals,
          values.appraisalType,
          values.staffNo,
          values.staffName,
          values.siteId,
          values.positionId,
          values.regionalIC
        )
      } else if (mode === "appraisal") {
        result = await client.appraisalReport(
          values.yearOfAppraisals,
          values.appraisalType,
          values.staffNo,
          values.staffName,
          values.siteId,
          values.positionId,
          values.regionalIC
        )
      } else if (mode === "progress") {
        result = await client.progressReport(
          values.yearOfAppraisals,
          values.appraisalType,
          values.staffNo,
          values.staffName,
          values.siteId,
          values.positionId,
          values.regionalIC
        )
      } else if (mode === "360") {
        //TODO: need change api name
        result = await client.degree360Report(
          values.yearOfAppraisals,
          values.staffNo,
          values.staffName,
          values.siteId,
          values.positionId,
          values.regionalIC
        )
      }

      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", result.fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (err) {
      console.log(err)
      message.error("Download Error !")
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Row gutter={24} className="report_table">
      <Form layout="inline" form={form} onFinish={onFinish}>
        <Col span={12}>
          <Form.Item
            label="Year of Appraisal"
            name="yearOfAppraisals"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select a cycle"
            >
              {options.codeOptions &&
                options.codeOptions.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={mode === "360" ? 0 : 12}>
          <Form.Item label="Appraisal Type" name="appraisalType">
            <Select
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select a type"
            >
              <Option value={1}>Managerial</Option>
              <Option value={0}>Non-Managerial</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a staff number" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a staff number" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Site / Department" name="siteId">
            <Select
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select a site / deparment"
            >
              {options.siteOrDepartmentList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Position" name="positionId">
            <Select
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select a position"
            >
              {options.positionList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Regional In Charge" name="regionalIC">
            <Select
              mode="multiple"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select a Regional IC"
            >
              {options.icOptions.map(item => (
                <Option key={item.staffNo} value={item.staffNo}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                loading={isDownloading}
              >
                Export
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

const CycleTableTemplate = ({ mode }) => {
  const [options, setOptions] = useState([])
  const [icOptions, setIcOptions] = useState([])
  const [codeOptions, setCodeOptions] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    const initPage = async () => {
      let client
      let res
      try {
        client = new AppraisalCycleClient()
        res = await client.getCode()
        setCodeOptions(res.content)

        client = new OtherClient()
        res = await client.get()
        setOptions(res.content)
        client = new UsersClient()
        res = await client.getRegionalIC()
        setIcOptions(res.content)
        setIsLoad(true)
      } catch (err) {
        message.error("System Error occur")
      }
    }

    initPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoad ? (
    <SearchForm
      mode={mode}
      options={{
        ...options,
        icOptions: icOptions,
        codeOptions: codeOptions,
      }}
    />
  ) : null
}

export default CycleTableTemplate
